/*
Template Name: Prompt - Tailwind CSS Multipurpose Landing Page Template
Version: 1.1.0
Author: coderthemes
Email: support@coderthemes.com
File: style Css File
*/
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  @apply relative scroll-smooth;
}

body {
  font-size: 15px;
  @apply font-body overflow-x-hidden text-gray-500;
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-semibold;
}

.vertical-rl {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

:root:is([data-mode=dark]) {
  color-scheme: dark;
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
    top: 10%;
    left: 5%;
  }
  100% {
    transform: rotate(360deg);
    top: 60%;
    left: 15%;
  }
}
@keyframes rotating2 {
  0% {
    transform: rotate(0deg);
    bottom: 10%;
    right: 10%;
  }
  100% {
    transform: rotate(360deg);
    bottom: 80%;
    right: 30%;
  }
}
@keyframes rotating3 {
  0% {
    transform: rotate(0deg);
    bottom: 0%;
    right: 65%;
  }
  100% {
    transform: rotate(360deg);
    bottom: 50%;
    right: 35%;
  }
}
.hero-with-shapes .shape1 {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 7rem;
  height: 7rem;
  background: url("/assets/images/shapes/rounded-square2.svg");
  animation: rotating alternate ease-in-out infinite 6s;
}
.hero-with-shapes .shape2 {
  position: absolute;
  bottom: 20%;
  right: 10%;
  width: 7rem;
  height: 7rem;
  background: url("/assets/images/shapes/rounded-square2.svg");
  animation: rotating2 alternate ease-in-out infinite 6s;
}
.hero-with-shapes .shape3 {
  position: absolute;
  bottom: 0%;
  right: 65%;
  width: 7rem;
  height: 7rem;
  background: url("/assets/images/shapes/rounded-square2.svg");
  animation: rotating3 alternate ease-in-out infinite 6s;
}

.logo-light {
  @apply hidden;
}

@screen lg {
  .logo-light {
    @apply hidden dark:block;
  }
  .logo-dark {
    @apply block dark:hidden;
  }
}
.navbar-nav .nav-item .nav-link {
  @apply font-medium relative tracking-wide flex items-center py-1.5 px-3.5 text-gray-800 
                   rounded-md text-sm cursor-pointer transition-all duration-300 bg-transparent;
}
.navbar-nav .nav-item .nav-link:is(.active, :active, :focus, :hover) {
  @apply text-primary;
}
.navbar-nav .nav-item .nav-item .nav-item .nav-link {
  @apply text-gray-800;
}

header.nav-sticky {
  @apply bg-white shadow;
}
header.dark .navbar-nav .nav-item .nav-link {
  @apply text-gray-100;
}
header.dark .navbar-nav .nav-item .nav-link:is(.active, :active, :focus, :hover) {
  @apply text-primary;
}
header.dark .navbar-nav .nav-item .fc-dropdown .nav-link {
  @apply text-gray-800;
}
header.dark .navbar-nav .nav-item .fc-dropdown .nav-link.active {
  @apply text-primary;
}
@screen lg {
  header.dark.nav-sticky .logo-light {
    @apply hidden;
  }
  header.dark.nav-sticky .logo-dark {
    @apply block;
  }
}
header.dark.nav-sticky .navbar-nav .nav-link {
  @apply text-gray-800 hover:text-primary;
}
header.dark.nav-sticky .navbar-nav .nav-link:is(.active, :active, :focus, :hover) {
  @apply text-primary;
}

.filter-options li a {
  @apply text-gray-700 px-4 py-1.5 rounded border border-gray-300 flex items-center justify-center text-sm transition-all duration-500;
}

.filter-options li:hover a,
.filter-options li.active a,
.filter-options li:active a,
.filter-options li:focus a {
  @apply bg-primary text-white border-transparent shadow-md shadow-primary/25;
}